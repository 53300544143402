import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import packageJson from "../package.json";
import { eventTrackerInitialize } from './Shared/service/eventService';

// import Footer from './Shared/Components/Footer/Footer';
// import Header  from './Shared/Components/Header/Header';

import AppRoutes from './app/routes'
const domNode = document.getElementById('root');
const root = createRoot(domNode);
console.log('react ap var',process.env.REACT_APP_ENABLE_KPI)
if(process.env.REACT_APP_ENABLE_KPI==="true"){
    eventTrackerInitialize();
}
console.log(`%cAirtel - Portal Team(v${packageJson.version}) ⓒ ${(new Date).getFullYear()}`, "color: #e40000; font-size: 18px; font-weight: bold")
root.render(<AppRoutes />);

window['dataLayer'] = window['dataLayer'] || [];
window['gtag'] = function () {
    window['dataLayer'].push(arguments);
};
window['gtag']('js', new Date());
window['gtag']('config', process.env.REACT_APP_GOOGLE_ANALYTIC_ID);
window['reactParams']={ opco:process.env.REACT_APP_OPCO};